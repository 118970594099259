"use client";

import type React from "react";
import { Suspense } from "react";
import { usePathname } from "next/navigation";
import Header from "./Header";
import Loading from "../loading";
import { NavigationEvents } from "./NavigationEvents";

interface LayoutProps {
	children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
	const pathname = usePathname();
	const isFullWidth = pathname === "/jake-jenerator";

	return (
		<div
			className="flex flex-col min-h-screen bg-gray-50 dark:bg-gray-900"
			data-overlay-container
		>
			<Header />
			<NavigationEvents />
			<main
				className={
					isFullWidth
						? "flex-grow pt-0"
						: "flex-grow px-4 py-8 md:px-8 lg:px-12"
				}
			>
				<div className={isFullWidth ? "w-full" : "max-w-4xl mx-auto"}>
					<Suspense fallback={<Loading />}>{children}</Suspense>
				</div>
			</main>
			<footer className="bg-linear-to-r from-slate-800 to-slate-900 py-6 text-center text-white">
				<p>
					&copy; {new Date().getFullYear()} Sean Dearnaley. All rights reserved.
				</p>
			</footer>
		</div>
	);
};

export default Layout;
