"use client";

import { useTheme } from "next-themes";
import { Button } from "@/components/ui/button";
import { Moon, Sun } from "lucide-react";
import React from 'react';

const ThemeToggle: React.FC = () => {
	const { theme, setTheme } = useTheme();
	const [mounted, setMounted] = React.useState(false);

	React.useEffect(() => {
		setMounted(true);
	}, []);

	if (!mounted) {
		return <Button 
			variant="ghost" 
			size="icon" 
			className="w-9 px-0"
			aria-label="Toggle theme"
		>
			<Sun className="h-[1.2rem] w-[1.2rem]" />
		</Button>;
	}

	return (
		<Button
			variant="ghost"
			size="icon"
			onClick={() => setTheme(theme === "light" ? "dark" : "light")}
			className="w-9 px-0"
			aria-label={theme === "light" ? "Switch to dark theme" : "Switch to light theme"}
		>
			{theme === "light" ? <Moon className="h-[1.2rem] w-[1.2rem]" /> : <Sun className="h-[1.2rem] w-[1.2rem]" />}
		</Button>
	);
};

export default ThemeToggle;
