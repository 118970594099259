"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { useCallback } from "react";

const NavLinks: React.FC = () => {
	const pathname = usePathname();

	const links = [
		{ href: "/", label: "Blog" },
		{ href: "/pages/about", label: "About Me" },
		{ href: "/contact", label: "Contact" }, // Add the contact link
	];

	const getLinkClassName = useCallback(
		(href: string) => {
			const baseClasses =
				"text-white hover:text-blue-300 px-3 py-2 rounded-md text-sm font-medium transition-colors";
			const activeClasses = "bg-white/20 text-blue-300";
			return `${baseClasses} ${pathname === href ? activeClasses : ""}`;
		},
		[pathname],
	);

	return (
		<>
			{links.map((link) => (
				<Link
					key={link.href}
					href={link.href}
					className={getLinkClassName(link.href)}
					prefetch={true}
				>
					{link.label}
				</Link>
			))}
		</>
	);
};

export default NavLinks;
