import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Mail, Github, Linkedin } from "lucide-react";
import NavLinks from "./NavLinks";
import ThemeToggle from "./ThemeToggle";

const Header: React.FC = () => {
	return (
		<header className="bg-linear-to-r from-slate-800 to-slate-900 text-white py-3 sm:py-5 sticky top-0 z-10">
			<div className="max-w-6xl mx-auto px-4">
				<div className="flex flex-col sm:flex-row items-center justify-between">
					<div className="flex flex-col sm:flex-row items-center sm:items-start space-y-3 sm:space-y-0 sm:space-x-6">
						<Image
							src="/media/face_512.png"
							alt="Sean Dearnaley"
							width={128}
							height={128}
							priority
							className="hidden sm:block w-24 h-24 sm:w-32 sm:h-32 rounded-full border-4 border-white shadow-xl hover:scale-105 transition-transform duration-300"
						/>
						<div className="text-center sm:text-left">
							<h1 className="text-3xl sm:text-4xl font-bold mb-1 sm:mb-2">
								Sean Dearnaley
							</h1>
							<p className="text-lg sm:text-xl font-light mb-3">
								Software Engineer
							</p>
							<div className="flex flex-col sm:flex-row gap-3 justify-center sm:justify-start items-center">
								<div className="flex gap-3">
									<NavLinks />
								</div>
								<div className="flex gap-3">
									<SocialLink
										href="https://github.com/seandearnaley"
										icon={<Github />}
										label="GitHub"
									/>
									<SocialLink
										href="https://www.linkedin.com/in/sean-dearnaley-8577a973"
										icon={<Linkedin />}
										label="LinkedIn"
									/>
									<SocialLink href="/contact" icon={<Mail />} label="Email" />
									<ThemeToggle />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

interface SocialLinkProps {
	href: string;
	icon: React.ReactNode;
	label: string;
}

const SocialLink: React.FC<SocialLinkProps> = ({ href, icon, label }) => (
	<Link
		href={href}
		target={href.startsWith("http") ? "_blank" : undefined}
		rel={href.startsWith("http") ? "noopener noreferrer" : undefined}
		className="bg-white/20 p-2 rounded-full hover:bg-white/30 transition-all duration-300"
		aria-label={label}
	>
		{React.cloneElement(icon as React.ReactElement<{ className?: string }>, {
			className: "h-6 w-6 text-white",
		})}
	</Link>
);

export default Header;
