"use client";

import { useEffect, Suspense } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import NProgress from "nprogress";

function NavigationEventsContent() {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	useEffect(() => {
		const handleStart = () => NProgress.start();
		const handleComplete = () => NProgress.done();

		const url = pathname + searchParams.toString();
		handleStart();

		// Simulate a delay to show the progress bar
		const timer = setTimeout(() => {
			handleComplete();
		}, 100);

		return () => {
			clearTimeout(timer);
			NProgress.remove();
		};
	}, [pathname, searchParams]);

	return null;
}

export function NavigationEvents() {
	return (
		<Suspense fallback={null}>
			<NavigationEventsContent />
		</Suspense>
	);
}
